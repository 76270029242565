
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import NotRecords from "@/components/NotRecords.vue";

import { mapActions, mapMutations, mapState } from "vuex";
import Company from "@/models/Company";
import { MUT_NOTIFICATION } from "@/store/mutations/types";
import { funtionsPhone } from "@/utils/mixins";

const BtnEditCompany = () =>
  import(
    /* webpackChunkName: "Edit Company" */ "@/views/Companies/buttons/BtnEditCompany.vue"
  );
export default Vue.extend({
  mixins: [funtionsPhone],
  components: { NotRecords, BtnEditCompany },
  name: "crm-companies",
  data() {
    return {
      dialogConfirmRemove: false,
      currentCompany: {
        name: "",
        email: "",
        description: "",
        phone: "",
        uuid: "",
      },
      headers: [
        { text: this.$t("labelCompany"), value: "name", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: this.$t("labelPhone"), value: "phone", sortable: false },
        {
          text: this.$t("labelDescription"),
          value: "description",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("crmCompaniesModule", ["companies", "loading"]),
  },
  mounted() {
    this.actGetCompanies();
  },
  methods: {
    ...mapActions("crmCompaniesModule", [
      "actGetCompanies",
      "actRemoveCompany",
    ]),
    ...mapMutations([MUT_NOTIFICATION]),
    setCompanyToRemove(company: Company): void {
      this.currentCompany = company;
      this.dialogConfirmRemove = true;
    },
    remove() {
      this.actRemoveCompany(this.currentCompany.uuid).then(() => {
        this.actGetCompanies();
        this.dialogConfirmRemove = false;
        this[MUT_NOTIFICATION]({
          title: this.$t("notification"),
          body: this.currentCompany.name,
          type: "info",
          show: true,
        });
      });
    },
  },
});
